import * as React from 'react'
import { TableDateGroupHeader } from './TableDateGroupHeader'
import { TableJobRowsGroup } from './TableJobRowsGroup'

type TableDateGroupProps = {
  date: string
  productionPlanningLines: any
  hideItems: boolean
  area?: string
  showCompletedToday: boolean
}

export default function TableDateGroup(props: TableDateGroupProps) {
  const { date, productionPlanningLines, hideItems, area, showCompletedToday } = props

  let currentColor = 'bg-white'
  const toggleColor = () => {
    currentColor = currentColor === 'bg-light' ? 'bg-white' : 'bg-light'
    return currentColor
  }

  const productionPlanningLinesByJobId = productionPlanningLines.reduce((acc, line) => {
    acc[line.job_id] = acc[line.job_id] || []
    acc[line.job_id].push(line)
    return acc
  }, {})

  return (
    <>
      <TableDateGroupHeader date={date} />
      {Object.keys(productionPlanningLinesByJobId).map(jobId => {
        const lines = productionPlanningLinesByJobId[jobId]
        return (
          <TableJobRowsGroup
            key={jobId}
            jobId={jobId}
            productionPlanningLines={lines}
            hideItems={hideItems}
            area={area}
            showCompletedToday={showCompletedToday}
            colorClass={toggleColor()}
          />
        )
      })}
    </>
  )
}
