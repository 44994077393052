import * as React from 'react'
import TableHead from './TableHead'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'
import TableDateGroup from './TableDateGroup'
import { DEFAULT_COLSPAN } from './TableDateGroupHeader'

type TableProps = {
  isLoading: boolean
  isError: boolean
  error?: any
  hideItems: boolean
  productionPlanningLines?: any
  area?: string
  showCompletedToday: boolean
}

export default function Table(props: TableProps) {
  const { isLoading, isError, hideItems, productionPlanningLines, error, area, showCompletedToday } = props
  return (
    <div>
      <table className="table table-print-sm">
        <TableHead hideItems={hideItems} showCompletedToday={showCompletedToday} />
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={DEFAULT_COLSPAN}>
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin fa-2x" />
                </div>
              </td>
            </tr>
          )}
          {!isLoading &&
            Object.keys(productionPlanningLines).map(date => {
              const lines = productionPlanningLines[date]
              if (!lines.length) return null
              return (
                <TableDateGroup
                  key={date}
                  date={date}
                  hideItems={hideItems}
                  productionPlanningLines={productionPlanningLines[date]}
                  area={area}
                  showCompletedToday={showCompletedToday}
                />
              )
            })}
          {isError && (
            <tr>
              <td colSpan={DEFAULT_COLSPAN}>
                <ErrorMessage className="d-inline error-message" error={error} isError={isError} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
