import * as React from 'react'
import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { minutesToTime } from '../shared/utils'
import ScanPopupButton from '../job_plannings/scan_popup_button/ScanPopupButton'
import ProductionPlanningDictionariesContext from './ProductionPlanningDictionariesContext'

type TableRowProps = {
  productionPlanningLine: any
  hideItems: boolean
  area: string
  showCompletedToday: boolean
  className?: string
  hoveredJobId?: string
  setHoveredJobId?: (jobId: string) => void
  toggleCollapse: () => void
}

function TableRow(props: TableRowProps) {
  const {
    productionPlanningLine,
    hideItems,
    area,
    showCompletedToday,
    hoveredJobId,
    setHoveredJobId,
    toggleCollapse,
    className = '',
  } = props
  const machineHours = parseFloat(Number(productionPlanningLine.machine_time).toFixed(2))
  const manHours = parseFloat(Number(productionPlanningLine.staffing).toFixed(2))

  const isCompletedForArea = productionPlanningLine.process_areas[area] !== false
  const isCompletedClass = isCompletedForArea ? ' production-planning-is-completed' : ''
  let specialColorClass = specialColor(productionPlanningLine.special)

  const manTime = minutesToTime(manHours)
  const machineTime = minutesToTime(machineHours)
  const { currentUser, availableAreas } = useContext(ProductionPlanningDictionariesContext)
  const queryClient = useQueryClient()

  const hovered = hoveredJobId === productionPlanningLine.job_id
  const classNameHovered = hovered ? ' hovered ' : ''

  return (
    <tr key={productionPlanningLine.key} className={className} title={`Ordrestyrer: ${productionPlanningLine.order_manager}`}>
      <td
        className={'text-nowrap production-planning-job-id ' + specialColorClass + isCompletedClass + classNameHovered}
        onMouseOver={() => setHoveredJobId(productionPlanningLine.job_id)}
        onMouseOut={() => setHoveredJobId(null)}
        onClick={() => toggleCollapse()}
      >
        {productionPlanningLine.job_id}
      </td>

      <td className={specialColorClass + isCompletedClass}>
        <ScanPopupButton
          buttonClass=""
          buttonTitle={productionPlanningLine.order_number}
          availableAreas={availableAreas}
          currentUser={currentUser}
          orderNumber={productionPlanningLine.order_number}
          orderId={productionPlanningLine.solution_id}
          onSuccessfulScan={() => {
            queryClient.invalidateQueries('production_planning_overview')
          }}
        />
      </td>
      <td className={specialColorClass + isCompletedClass}>{productionPlanningLine.customer_title}</td>
      <td className={specialColorClass + isCompletedClass}>{productionPlanningLine.job_title}</td>
      <td className={specialColorClass + isCompletedClass}>{productionPlanningLine.solution_id}</td>
      <td className={specialColorClass + isCompletedClass}>{productionPlanningLine.solution_name}</td>
      <td className={specialColorClass + isCompletedClass}>{machineTime}</td>
      <td className={specialColorClass + isCompletedClass}>{manTime}</td>
      <td className={specialColorClass + isCompletedClass}>{productionPlanningLine.spak}</td>
      {showCompletedToday && (
        <>
          <td className={specialColorClass + isCompletedClass}>{productionPlanningLine.operator}</td>
          <td className={specialColorClass + isCompletedClass}>{productionPlanningLine.machine}</td>
        </>
      )}
      <td className="border-left border-right">
        <ProcessAreas processAreas={productionPlanningLine.process_areas} />
      </td>
      {!hideItems && (
        <>
          <td className={isCompletedClass}>{productionPlanningLine.items && productionPlanningLine.items[0]}</td>
          <td className={isCompletedClass}>{productionPlanningLine.items && productionPlanningLine.items[1]}</td>
        </>
      )}
    </tr>
  )
}

function ProcessAreas({ processAreas }) {
  return (
    <div className="d-flex">
      {Object.keys(processAreas).map(processArea => {
        const completed = processAreas[processArea]
        const colorClass = completed === false ? 'badge-light-grey' : 'badge-success'
        return (
          <span key={processArea} className={`badge ${colorClass} text-nowrap mr-2 p-2`}>
            {processArea}
          </span>
        )
      })}
    </div>
  )
}

function specialColor(special: string) {
  if (special === 'SUPER EKSPRES') {
    return 'bg-light-danger'
  } else if (special) {
    return 'bg-warning'
  }
  return ''
}

export default TableRow
